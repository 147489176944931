<template>
  <div>
    <v-card class="table-card mt-4">
      <v-data-table
        ref="scheduleTemplateTable"
        :key="scheduleTemplate.tableKey"
        class="base-table draggable-table"
        fixed-header
        hide-default-footer
        :headers="headers"
        :items="lines"
        :items-per-page="-1"
      >
        <template #item="props">
          <tr
            class="body-row"
            :data-row-order="props.item.rowOrder"
            :data-row-day-number="props.item.dayNumber"
          >
            <td>{{ props.item.dayNumber }}</td>
            <td class="item-order text-center">{{ getLineIndex(props.item) }}</td>
            <td>{{ props.item.academicWork && props.item.academicWork.name }}</td>
            <td>{{ props.item.eventPlace && props.item.eventPlace.fullAddress }}</td>
            <td>{{ props.item.chapter ? `${props.item.chapter?.name}. ${props.item.name}` : props.item.name }}</td>
            <td class="text-right">
              <v-icon
                color="btnIcon"
                class="mr-2 icon-btn copy-btn"
                size="20"
                title="Создать копию строки шаблона расписания"
                @click="copyScheduleTemplateLine(props.item)"
              >
                mdi-content-copy
              </v-icon>

              <v-icon
                color="btnIcon"
                class="mr-1 icon-btn edit-btn"
                size="22"
                title="Редактировать строку"
                @click="editScheduleTemplate(props.item)"
              >
                mdi-pencil-outline
              </v-icon>

              <v-icon
                color="btnIcon"
                class="icon-btn remove-btn"
                size="22"
                title="Удалить строку"
                @click="confirmRemoveScheduleTemplateLine(props.item)"
              >
                mdi-trash-can-outline
              </v-icon>
            </td>
          </tr>
        </template>

        <template slot="no-data">
          <div>В шаблоне расписания нет строк</div>
        </template>
      </v-data-table>
    </v-card>

    <ScheduleTemplateLineDialog
      :dialog="dialog"
      :dialogTitle="dialogTitle"
      :chaptersList="chaptersList"
      :scheduleTemplateLine="selectedItem"
      :validationErrors="validationErrors"
      @saveScheduleTemplateLine="saveScheduleTemplateLine"
      @closeDialog="closeDialog"
    />

    <DeleteConfirmDialog
      :dialog="confirmDialog"
      :message="'Вы уверены, что хотите удалить строку шаблона расписания?'"
      @confirm="removeScheduleTemplateLine"
      @closeConfirmModal="closeConfirmDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventBus } from '@/main'
import _cloneDeep from 'lodash/cloneDeep'
import { DraggableTable } from '@/scripts'
import { ScheduleTemplate, ScheduleTemplateLine, EventPlace } from '@/models'
import ScheduleTemplateLineDialog from '@/components/department/scheduleTemplate/dialogs/ScheduleTemplateLine'
import DeleteConfirmDialog from '@/components/dialogs/DeleteConfirm'

export default {
  name: 'ScheduleTemplateTable',

  props: {
    infoBlockHeight: Number,
    scheduleTemplateId: Number,
    scheduleTemplate: ScheduleTemplate
  },

  components: {
    ScheduleTemplateLineDialog,
    DeleteConfirmDialog
  },

  async created() {
    eventBus.$on('createScheduleTemplateLine', this.createScheduleTemplateLine)
  },

  mounted() {
    this.draggableTable.setTable(this.$refs.scheduleTemplateTable.$el)
    this.draggableTable.init()
    this.draggableTable.setScheduleTemplate(this.scheduleTemplate)

    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  beforeDestroy() {
    eventBus.$off('createScheduleTemplateLine')
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    headers: [
      { text: 'День', value: 'dayNumber', sortable: false, align: 'left', width: '60px' },
      { text: 'Порядок', value: 'index', sortable: false, align: 'center', width: '60px' },
      { text: 'Вид занятия', value: 'academicWork', sortable: false, align: 'left' },
      { text: 'Место', value: 'eventPlace', sortable: false, align: 'left', width: '200px' },
      { text: 'Тема', value: 'name', sortable: false, align: 'left' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right', width: '120px' }
    ],

    dataLoaded: false,
    dialogTitle: null,
    dialog: false,
    confirmDialog: false,
    selectedItem: null,

    draggableTable: new DraggableTable()
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId']),

    lines() {
      return this.scheduleTemplate.lines
    },

    validationErrors() {
      return this.scheduleTemplate.validationErrors
    },

    chaptersList() {
      const list = new Set()
      this.lines.forEach(line => line.chapter && list.add(line.chapter))

      return [...list]
    }
  },

  methods: {
    createScheduleTemplateLine() {
      this.selectedItem = new ScheduleTemplateLine()

      if (localStorage.getItem(`schTemp-${this.scheduleTemplateId}`)) {
        const place = JSON.parse(localStorage.getItem(`schTemp-${this.scheduleTemplateId}`))
        this.selectedItem.eventPlace = EventPlace.buildFromAPI(place)
      }

      this.dialogTitle = 'Добавление строки шаблона расписания'
      this.dialog = true
    },

    copyScheduleTemplateLine(item) {
      this.selectedItem = _cloneDeep(item)
      this.selectedItem.scheduleTemplateLineId = null
      this.selectedItem.dayNumber = null
      this.selectedItem.order = null
      this.selectedItem.rowOrder = null
      this.dialogTitle = 'Копирование строки шаблона расписания'
      this.dialog = true
    },

    editScheduleTemplate(item) {
      this.selectedItem = item
      this.dialogTitle = 'Редактирование строки шаблона расписания'
      this.dialog = true
    },

    removeScheduleTemplateLine() {
      this.$emit('removeScheduleTemplateLine', this.selectedItem)
      this.closeConfirmDialog()
    },

    saveScheduleTemplateLine(item) {
      this.$emit('saveScheduleTemplateLine', item)
    },

    getLineIndex(item) {
      const index = this.lines.findIndex(el => el === item)
      return index + 1
    },

    setTableHeight() {
      const otherElements = 185 + this.techMessageHeight + this.infoBlockHeight

      if (this.$refs?.scheduleTemplateTable) {
        this.$refs.scheduleTemplateTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    },

    confirmRemoveScheduleTemplateLine(item) {
      if (this.scheduleTemplate.isAllowToRemove(item)) {
        this.selectedItem = item
        this.confirmDialog = true
      } else {
        this.$toastr('error', '', 'Дни занятий должны идти подряд')
      }
    },

    closeDialog() {
      this.dialog = false
      this.dialogTitle = null
      this.selectedItem = null
      this.scheduleTemplate.clearValidationErrors()
    },

    closeConfirmDialog() {
      this.confirmDialog = false
      this.selectedItem = null
    }
  },

  watch: {
    infoBlockHeight() {
      this.setTableHeight()
    },

    "scheduleTemplate.tableKey": {
      handler() {
        this.$nextTick(() => {
          this.draggableTable.setTable(this.$refs.scheduleTemplateTable.$el)
          this.setTableHeight()
        })
      }
    }
  }
}
</script>

<style scoped>
.base-table {
  border-radius: 10px 10px 0 0 !important;
}

/*.base-table >>> th, .custom-th {*/
/*  height: 32px !important;*/
/*}*/

/*.base-table >>> .custom-th {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  padding-left: 20px;*/
/*  padding-right: 20px;*/
/*  font-size: 14px;*/
/*}*/

/*.base-table >>> .custom-th.chapter {*/
/*  justify-content: space-between;*/
/*  background-color: #F0F0F0 !important;*/
/*  cursor: pointer;*/
/*}*/

/*.base-table >>> .total-chapter-hours {*/
/*  width: 40px;*/
/*  margin-right: 140px;*/
/*  text-align: center;*/
/*}*/


.draggable-table {

}

.draggable-table__drag {
  font-size: 14px;
  position: absolute;
  width: 100%;
  border: 1px solid #f1f1f1;
  z-index: 10;
  cursor: grabbing;
  -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);

  opacity: 1;
}

tbody tr {
  cursor: grabbing;
}

tbody tr.is-dragging {
  background: var(--disabled-color);
}

tbody tr.is-dragging .icon-btn {
  color: var(--selectedRow-color) !important;
}

tbody tr.is-dragging td {
  color: var(--selectedRow-color);
}
</style>