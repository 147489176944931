<template>
  <v-row justify="center">
    <v-dialog :value="dialog" max-width="700" persistent @input="closeDialog">
      <v-card class="dialog-card">
        <v-card-title class="headline accent--text pb-4">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text outlined tile class="border-top">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0 pt-5 px-0">
                <v-autocomplete
                  v-model="scheduleTemplateLineLocal.dayNumber"
                  label="День"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора"
                  :items="dayNumbers"
                  :error-messages="dayNumberErrorMessages"
                  @input="$v.scheduleTemplateLineLocal.dayNumber.$touch()"
                  @blur="$v.scheduleTemplateLineLocal.dayNumber.$touch()"
                />

                <v-autocomplete
                  v-model="scheduleTemplateLineLocal.academicWork"
                  label="Вид занятия"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора"
                  return-object
                  :items="academicWorks"
                  item-text="name"
                  :error-messages="errorMessages.academicWork"
                  @input="$v.scheduleTemplateLineLocal.academicWork.$touch()"
                  @blur="$v.scheduleTemplateLineLocal.academicWork.$touch()"
                />

                <v-autocomplete
                  v-model="scheduleTemplateLineLocal.eventPlace"
                  label="Место"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора"
                  return-object
                  :items="eventPlaces"
                  item-text="fullAddress"
                />

                <v-autocomplete
                  v-if="!noChapter"
                  v-model="scheduleTemplateLineLocal.chapter"
                  class="mb-0 pb-0"
                  label="Раздел"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора"
                  item-value="id"
                  hide-no-data
                  return-object
                  :items="chapterList.list"
                  item-text="name"
                  :error-messages="errorMessages.chapter"
                  @input="$v.scheduleTemplateLineLocal.chapter.$touch()"
                  @blur="$v.scheduleTemplateLineLocal.chapter.$touch()"
                />

                <v-text-field
                  v-if="noChapter"
                  v-model="chapterStr"
                  label="Раздел"
                  placeholder="Введите название раздела"
                  :error-messages="errorMessages.chapterStr"
                  @input="$v.chapterStr.$touch()"
                  @blur="$v.chapterStr.$touch()"
                />

                <v-checkbox
                  v-model="noChapter"
                  class="default-event-place mt-0 mb-4"
                  label="отсутствует в справочнике"
                  hide-details
                />

                <v-textarea
                  v-model="scheduleTemplateLineLocal.name"
                  label="Тема"
                  no-resize
                  auto-grow
                  rows="1"
                  :error-messages="errorMessages.name"
                  @input="$v.scheduleTemplateLineLocal.name.$touch()"
                  @blur="$v.scheduleTemplateLineLocal.name.$touch()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="$v.$invalid"
            :loading="createChapterLoading"
            color="success"
            text
            @click="saveLine"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { catalogApi, chapterApi, eventPlaceApi } from '@/api'
import { mapGetters } from 'vuex'
import { eventBus } from '@/main'
import _cloneDeep from 'lodash/cloneDeep'
import { scheduleHours, setValidateMessages } from '@/scripts/validation'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { ScheduleTemplateLine, AcademicWork, EventPlace, ChapterList, Chapter } from '@/models'
import _debounce from 'lodash/debounce'

export default {
  name: 'ScheduleTemplateLine',

  props: {
    dialog: Boolean,
    dialogTitle: String,
    chaptersList: Array,
    scheduleTemplateLine: Object,
    validationErrors: Object
  },

  async created() {
    eventBus.$on('closeScheduleTemplateLineDialog', this.closeDialog)
    await this.getDictionaries()
  },

  beforeDestroy() {
    eventBus.$off('closeScheduleTemplateLineDialog')
  },

  data: () => ({
    academicWorks: [],
    eventPlaces: [],
    chapters: [],
    searchChapters: '',
    chapterList: new ChapterList(),
    chapterListLoading: false,
    noChapter: false,
    chapterStr: '',
    createChapterLoading: false,

    scheduleTemplateLineLocal: new ScheduleTemplateLine()
  }),

  validations() {
    return {
      scheduleTemplateLineLocal: {
        dayNumber: { required },
        academicWork: { required },
        eventPlace: { required },
        chapter: { required: requiredIf(() => !this.noChapter), maxLength: maxLength(300) },
        name: { required, maxLength: maxLength(300) },
      },
      chapterStr: { required: requiredIf(() => this.noChapter), maxLength: maxLength(300) }
    }
  },

  computed: {
    ...mapGetters('department', ['departmentId', 'department']),

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.scheduleTemplateLineLocal.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.scheduleTemplateLineLocal[key])
      })

      allErrors.chapterStr = setValidateMessages(this.$v.chapterStr)

      return allErrors
    },

    isEdit() {
      return this.scheduleTemplateLineLocal.rowOrder
    },

    dayNumbers() {
      return Array.from({ length: 600 }, (_, i) => i + 1)
    },

    dayNumberErrorMessages() {
      return [...this.errorMessages.dayNumber, ...this.validationErrors.dayNumber]
    }
  },

  methods: {
    async getDictionaries() {
      try {
        const [academicWorks, eventPlaces, chapters] = await Promise.all([
          catalogApi.getAcademicWorks(),
          eventPlaceApi.getEventPlaces(this.departmentId),
          chapterApi.findAll(this.departmentId),
        ])

        this.academicWorks = academicWorks.map(el => AcademicWork.buildFromAPI(el))
        this.eventPlaces = eventPlaces.map(el => EventPlace.buildFromAPI(el))
        const sortChapters = chapters.map(el => {
          el.name?.toUpperCase();
          return el
        }).sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        })

        this.chapterList.buildFromAPI(sortChapters)

      } catch (e) {
        console.log(e.status)
      }
    },

    async saveLine() {
      // v-combobox v-model не меняет значение, пока не уберешь фокус
      //this.$refs["chapter"].blur()

      try {
        if (this.noChapter) {
          this.createChapterLoading = true
          const existingChapter =  this.chapterList.list.find(el => el.name === this.chapterStr?.trim())
          if (existingChapter) {
            this.scheduleTemplateLineLocal.chapter = existingChapter
          } else {
            const newChapter = new Chapter()
            newChapter.name = this.chapterStr
            newChapter.department = this.department
            const data = await chapterApi.add(newChapter)
            this.chapterList.addChapter(data)
            this.scheduleTemplateLineLocal.chapter = data
          }
        }

        this.$nextTick(() => {
          this.$emit('saveScheduleTemplateLine', _cloneDeep(this.scheduleTemplateLineLocal))
        })
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения раздела расписания')
      } finally {
        this.createChapterLoading = false
      }
    },

    resetModal() {
      this.$v.$reset()
      this.scheduleTemplateLineLocal = new ScheduleTemplateLine()
      this.chapterStr = ''
      this.noChapter = false
    },

    closeDialog() {
      this.resetModal()
      this.$emit('closeDialog')
    }
  },

  watch: {
    scheduleTemplateLine(val) {
      if (val) {
        this.scheduleTemplateLineLocal = _cloneDeep(val)
      }
    },

    searchChapters(val) {
      if (val?.length >= 3) {
        this.chapters = this.chaptersList.filter(el => el.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1)
      } else {
        this.chapters = []
      }
    }
  }
}
</script>